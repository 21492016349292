.outer-box {
    @apply absolute top-0 right-0 w-full h-full bg-darkGrey bg-opacity-50 
}
.box-two {
    @apply absolute top-0 right-0 w-[50rem] h-full bg-white h-full shadow-lg py-5 px-10 overflow-y-scroll
}
.input{
    @apply text-sm font-pop400 text-midGrey border border-midGrey border-solid rounded-xl p-4 h-[3.125rem] focus:border-primeBlue focus:border-2 focus:outline-none focus:ring-primeBlue mb-1
}
.option {
    @apply text-base font-pop400 text-midGrey
}
.heading-box {
    @apply flex flex-row justify-between
}
.title {
    @apply font-pop600 text-2xl text-darkTeal
}
.textarea-box {
    @apply w-full mt-2 text-sm font-pop400 text-midGrey border border-midGrey border-solid rounded-xl p-4 focus:border-primeBlue focus:border-2 focus:outline-none focus:ring-primeBlue mb-1
}
.footer-btn {
    @apply bg-darkTeal w-[22rem] h-[3.125rem] rounded-xl text-white text-sm font-pop500
}