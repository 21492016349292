.body{
    margin-left: 0 !important;
}
.headingOne{
    text-align: center;
    font-size: 40px !important;
    font-family: 'Poppins-SemiBold'!important;
    color: #68BD45;
    padding-top: 130;
}
.headingTwo{
    text-align: center;
    font-size: 24px !important;
    font-family: 'Poppins-Medium',!important;
    color: #fff;
    margin-top: 20px !important;
}