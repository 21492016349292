.box {
    @apply w-[37.5rem] h-auto flex flex-col rounded-xl p-[2.5rem] mb-[5rem] bg-lightGrey
}
.heading {
    @apply text-xl font-pop600 
}
.inner-box {
    @apply my-[1.25rem]
}
.input {
     @apply text-sm font-pop400 border border-midGrey border-solid rounded-xl p-4 w-[32.5rem] focus:border-primeBlue focus:border-2 focus:outline-none focus:ring-primeBlue mb-3
}
.footer {
    @apply flex items-center self-center
}
.submit-btn {
    @apply w-[8.43rem] h-[3.125rem] rounded-xl bg-darkTeal text-white font-pop600 text-base
}
.info {
    @apply flex items-center justify-center
}
.heading-two {
    @apply text-center mb-2 text-base font-pop600
}
.thankyou-text {
    @apply text-center mb-10 font-pop400
}
.text {
    @apply font-pop400
}