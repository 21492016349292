.img-txt {
    @apply text-center text-sm font-pop500 text-darkTeal
}
.img {
    @apply text-center mt-1 flex justify-center mt-5 mb-5
}
.btn-box {
    @apply flex justify-center mt-1
}
.img-btn {
    @apply w-[8.43rem] h-[2.5rem] self-center bg-darkTeal text-white rounded-md
}