.modal-body {
    @apply inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-20 sm:align-middle w-[50rem] h-auto
}
.inner-modal-body {
    @apply px-4 pt-5 pb-4 sm:p-6 sm:pb-4
}
.input{
    @apply text-darkGrey text-sm font-pop400 border border-midGrey border-solid rounded-xl p-4 h-[3.125rem] focus:border-primeBlue focus:border-2 focus:outline-none focus:ring-primeBlue
}
.select-input{
    @apply w-[16.5rem] text-darkGrey text-sm font-pop400 border border-midGrey border-solid rounded-xl p-4 h-[3.125rem] focus:border-primeBlue focus:border-2 focus:outline-none focus:ring-primeBlue
}