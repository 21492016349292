.title {
    @apply text-2xl font-pop600 text-darkTeal
}
.add-distributor-btn {
    @apply h-[2.5rem] w-[10.24rem] bg-primeBlue font-pop600 text-base text-white rounded-lg
}
.sort-input {
    @apply w-1/4 mx-0.5 p-2 rounded-xl text-center text-xs font-pop500 text-darkTeal border-[0.03rem] border-midGrey
}
.search-input {
    @apply border border-midGrey p-2 rounded-xl text-midGrey font-pop500 text-sm w-[16rem] text-center
}
.list-title {
    @apply text-base font-pop400 text-midGrey text-center 
}
.list-value {
    @apply text-center text-base font-pop400 text-darkGrey
}
.first {
    @apply flex flex-row justify-between items-center
}
.grid-body {
    @apply grid grid-cols-7 gap-2 mt-5
}
.t-body {
    @apply border border-lightGrey rounded-t-xl
}
.table-list {
    @apply grid grid-cols-5 border-b border-lightGrey py-2 items-center
}