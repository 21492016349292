.modal-btn {
    @apply w-[21.5rem] h-[3.125rem] bg-darkTeal rounded-xl text-white text-lg font-pop600
}
.input-title {
    @apply mb-3 text-sm font-pop500 text-darkTeal
}
.input{
    @apply w-[21.5rem] text-darkGrey text-sm font-pop400 border border-midGrey border-solid rounded-xl p-4 h-[3.125rem] focus:border-primeBlue focus:border-2 focus:outline-none focus:ring-primeBlue
}
.title {
    @apply font-pop600 text-2xl text-darkTeal
}
.first {
    @apply flex flex-row justify-between items-center
}
.add-feature-btn {
    @apply bg-primeBlue font-pop600 text-base text-white rounded-lg ml-5 h-[3.125rem] w-[13rem]
}
.option {
    @apply text-base font-pop400 text-midGrey
}
.table-list {
    @apply grid grid-cols-6 border-b border-lightGrey py-2 items-center
}