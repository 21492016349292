.header {
    @apply mb-[2.5rem]
}
.myBox {
    @apply w-[37.5rem] h-auto bg-lightGrey flex flex-col rounded-xl p-[2.5rem] mb-[2.5rem]
}
.heading {
    @apply font-pop600 text-xl
}
.lable {
   @apply font-pop500 mb-[0.3125rem] text-sm
}
.inputBox {
   @apply text-sm font-pop400 border border-midGrey border-solid rounded-xl p-4 w-[32.5rem] focus:border-primeBlue focus:border-2 focus:outline-none focus:ring-primeBlue mb-1
}
.errorMsg {
   @apply pl-4 text-red
}
.body {
    @apply mb-[2.5rem]
}
.btn {
   @apply w-[8.43rem] h-[3.18rem] rounded-xl bg-darkTeal text-white font-pop600 text-base
}
.priceBox {
    @apply flex justify-between items-center
}
.priceTxt {
    @apply font-pop500 text-sm text-darkTeal
}
.price {
    @apply font-pop600 text-3xl
}