.footer-box {
  @apply bg-footerBg p-5 pt-24  pb-5 h-[40rem];
}
.footer-links {
  @apply px-4 pt-5 pb-8 flex justify-between items-center;
}
.navlink {
  @apply font-pop600 text-midGrey mr-5 p-0.5 text-base;
}
.gridbox {
  @apply flex flex-col justify-center items-center  w-[20rem] h-64 border border-primeBlue border-solid rounded-xl;
}
.textOne {
  @apply text-primeGreen text-base font-pop600 py-1;
}
.textTwo {
  @apply text-white text-sm font-pop600;
}
.row-one {
  @apply flex justify-between px-4;
}
.logo-box {
  @apply flex items-center mb-2;
}
.row-one-col {
  @apply flex flex-col;
}
.download-text {
  @apply font-pop600 text-white text-base pb-2 flex justify-end;
}
.copyright {
  @apply flex  flex-col justify-center items-center mt-5  px-4; 
}
.copyright-text {
  @apply font-pop500 text-primeBlue text-base mt-5 text-midGrey text-center;
}
