.status-box {
    @apply w-[24rem] h-[11rem] bg-lightBlueTwo rounded-xl flex pl-5 py-5
}
.status-title {
    @apply text-base font-pop500 text-darkTeal
}
.status-value {
    @apply text-4xl font-pop600 text-darkTeal mt-2 mb-5
}
.button {
    @apply h-[2.5rem] bg-primeBlue rounded-lg text-base font-pop600 text-white
}
.acc-title {
    @apply text-base font-pop500 text-darkGrey
}
.acc-value {
    @apply text-darkGrey text-lg font-pop600 mt-2
}