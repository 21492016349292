.custCard {
    @apply flex flex-col items-center w-[23rem] h-[38rem] rounded-xl m-5 -mt-20 transition duration-200 ease-out hover:shadow-xl hover:scale-100 border border-lightGrey
}
.box-one { 
    @apply flex flex-col justify-center w-[23rem] h-[12rem] rounded-xl rounded-b-none bg-gradient-to-b from-secondGreen to-primeGreen
}
.plan-title {
    @apply text-left pl-8 font-pop600 text-3xl mt-16
}
.price {
    @apply text-darkTeal text-left pl-8 font-pop600 text-4xl mt-4 mb-1
}
.plan-validity {
    @apply text-left pl-8 text-base font-pop500 text-darkTeal
}
.plan-includes {
    @apply text-darkTeal text-left text-base font-pop600
}
.plan-list {
    @apply mt-3 text-center text-base font-pop400 text-darkTeal
}
.items {
    @apply flex justify-start items-center py-[0.3rem] 
}
.btn-box {
    @apply flex justify-center items-center text-base font-pop600
}
.button {
    @apply w-[18.125rem] h-[3.125rem] bg-darkTeal rounded-xl text-base font-pop600 text-white
}