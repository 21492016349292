.card-body {
    @apply w-[17rem] h-[15rem] bg-lightGrey rounded-xl flex flex-col justify-center items-center
}
.plan-type {
    @apply text-2xl font-pop600 text-darkTeal
}
.plan-price {
    @apply text-4xl font-pop600 text-darkTeal my-5
}
.plan-validity {
    @apply text-base font-pop500 text-darkTeal mb-8
}
.card-btn {
    @apply w-[11rem] h-[3rem] bg-primeGreen rounded-lg text-base font-pop600 text-white
}