.tab-list {
    @apply w-1/2 text-base font-pop500 text-darkTeal p-3 border-b border-b-lightGrey
}
.active {
    @apply border-b-2 border-darkTeal
}
.tabBtn {
    @apply w-[5rem] h-[1.87rem] text-xs font-pop500 rounded text-darkTeal p-2 border border-darkTeal
}
.tabInput {
    @apply w-[22rem] border border-midGrey border-solid rounded-xl p-4 h-[3.125rem]
}
.active-tab {
    @apply bg-darkTeal text-white

}