.headingOne {
  text-align: center;
  font-size: 40px !important;
  font-family: "Poppins-SemiBold" !important;
  color: #68bd45;
  padding-top: 130;
}
/* .stepTxt1 {
  background-color: "green" !important;
  text-align: "center" !important;
  display: "flex";
  align-items: "center";
  justify-content: "center";
  border-radius: 50;
  width: 65 !important;
  height: 65 !important;
  margin-right: 20 !important;
  font-size: 32 !important;
  font-family: "Poppins-SemiBold" !important;
} */
