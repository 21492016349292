.accordion-body {
    @apply my-2 bg-white shadow rounded-xl
}
.accordion {
    @apply flex justify-between items-center w-[22rem] h-[3rem] px-3
}
.accordion-item {
    @apply flex justify-between items-center rounded-xl transition-all duration-300 ease-in-out h-[2.5rem] px-3
}
.accordion-title {
    @apply text-sm font-pop500 text-darkTeal
}
.accordion-text {
    @apply text-xs font-pop400 text-darkGrey
}