@font-face {
  font-family: "Poppins-Regular";
  src: url("../assets/fonts/Poppins-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins-Medium";
  src: url("../assets/fonts/Poppins-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins-SemiBold";
  src: url("../assets/fonts/Poppins-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins-Bold";
  src: url("../assets/fonts/Poppins-Bold.ttf") format("truetype");
}


.H1 {
  font-size: 24;
  font-family: "Poppins-Bold";
  letter-spacing: 0;
}
.H2 {
  font-size: 16;
  font-family: "Poppins-SemiBold";
  letter-spacing: 0;
}
.H3 {
  font-size: 14;
  font-family: "Poppins-Medium";
  letter-spacing: 0;
}
.P {
  font-size: 14;
  font-family: "Poppins-Medium";
  letter-spacing: 0;
  line-height: 21;
}
.P_Regular {
  font-size: 14;
  font-family: "Poppins-Regular";
  letter-spacing: 0;
  line-height: 21;
}
.P_SemiBold {
  font-size: 14;
  font-family: "Poppins-SemiBold";
  letter-spacing: 0;
  line-height: 21;
}
.Note {
  font-size: 12;
  font-family: "Poppins-Medium";
  letter-spacing: 0.2;
}
.Note_Regular {
  font-size: 12;
  font-family: "Poppins-Regular";
  letter-spacing: 0.2;
}
.Meta {
  font-size: 10;
  font-family: "Poppins-Regular";
  letter-spacing: 0.2;
}
.Meta_Medium {
  font-size: 10;
  font-family: "Poppins-Medium";
  letter-spacing: 0.2;
}
.Meta_SemiBold {
  font-size: 10;
  font-family: "Poppins-SemiBold";
  letter-spacing: 0.2;
}
.Meta_Bold {
  font-size: 10;
  font-family: "Poppins-Bold";
  letter-spacing: 0.2;
}
.SubNote {
  font-size: 8;
  font-family: "Poppins-Medium";
  letter-spacing: 0.2;
}
.P_Regular {
  font-size: 10;
  font-family: "Poppins-Regular";
  letter-spacing: 0;
}
.Regular_12px {
  font-size: 12;
  font-family: "Poppins-Regular";
  letter-spacing: 0;
}
.SemiBold_12px {
  font-size: 12;
  font-family: "Poppins-SemiBold";
  letter-spacing: 0;
}
.SemiBold_24px{
  font-size: 24;
  font-family: "Poppins-SemiBold";
  letter-spacing: 0;
}