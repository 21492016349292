.paginationBtn {
    @apply bg-transparent my-0 mx-[2.5rem] p-0
}
.grid-item {
    @apply flex flex-row justify-start items-center
}
.sort-input {
    @apply w-1/4 mx-0.5 p-2 rounded-xl text-center text-xs font-pop500 text-darkTeal border-[0.03rem] border-midGrey
}
.filter-text {
    @apply text-xs font-pop500 text-darkTeal mr-2
}
.add-patient-btn {
    @apply h-[2.5rem] w-[8.43rem] bg-primeBlue font-pop600 text-base text-white rounded-lg
}
.patient-list {
    @apply flex flex-col items-start justify-center
}
.username {
   @apply text-sm font-pop500
}
.usertype {
   @apply text-sm font-pop400
}
.start-hb-btn {
    @apply w-[6.87rem] h-[2.18rem] text-xs font-pop600 bg-greenTwo rounded-md text-white
}