.card{
    max-width: 450px;
    height: 565px;
    border-radius: 10px !important;
    padding-top: 50px !important;
    transition: 0.2s ease-out;
    border: 2px solid #c3c6ce !important;
}
.card:hover{
    border-color: #008bf8 !important;
    box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.25);
    scale: 1.02;
}
.groupOne{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 350px;
    height: 172px;
    border-radius: 10px;
    background: linear-gradient(to bottom, #B5FF97, #68BD45);
}
.title{
    text-align: center;
    font-size: 24px;
    font-family: 'Poppins-SemiBold' !important;
}
.price-box{
    color: #153B50;
    text-align: center;
    font-size: 40px !important;
    font-family: 'Poppins-SemiBold' !important;
}
.includes{
    color: #153B50;
    text-align: center;
    font-size: 16px !important;
    font-family: 'Poppins-SemiBold' !important;
}
.limit{
    text-align: center;
    font-size: 16px !important;
    font-family: 'Poppins-Medium', !important;
    margin-top: -10px !important;
    color: '#153B50' !important;
}
.groupTwo{
    margin-Top: 35px;
    margin-bottom: 35px;
}
.info-txt{
    margin-top: 10 !important;
    text-align: center;
    font-size: 16px !important;
    font-family: 'Poppins-Regular' !important;
    color: "#034C8C" !important;
}
.info-txt-box{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10 70;
    border-top: 1.5px solid #8B979E33;
}
.tick-icon{
    color: green;
    font-size: 24px;
    margin-right: 5px;
}
.btn-box{
    display: flex;
    justify-content: center;
    align-items: center;
}
.primary-button{
    display: flex;
    justify-content: center;
     align-items: center;
     font-size: 16px !important;
     font-family: 'Poppins-SemiBold' !important;
    /* position: absolute; */
    /* bottom: 50px;
    left: 0px;
    right:0px; */
  }
.btnTxt{
    font-size: 16px !important;
    font-family: 'Poppins-SemiBold' !important;
}